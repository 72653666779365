<template>
  <div>
    <b-row>
      <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="exportNocData()">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FileIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">Download NOC Applications</h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="exportPetitionsNew()">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FileIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">Download Petition Applications</h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
      <!-- <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="exportComplaint()">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FileIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">Download Complaint Applications</h4>
            </div>
          </b-card>
        </b-link>
      </b-col> -->
      <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="downloadUsersData">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FilePlusIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">
                Download unapproved users
              </h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="uploadRegisteredUsers">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FilePlusIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">
                Upload Internal Registered Users
              </h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="downloadExtensionApplications">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FilePlusIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">
                Download Re-employment Extension Applications
              </h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="hasPermission('export_to_file')">
        <b-link @click="downloadJobNocApplications">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FilePlusIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">
                Download Noc for Job Applications
              </h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
    <InternalRegisteredUserUploadModal
      @modalClosed="onModalClosed"
      :key="`create-${UploadRegisteredUserModalCount}`"
    />
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import axios from "axios";
import moment from "moment";
import InternalRegisteredUserUploadModal from "@/components/admin/InternalRegisteredUserUploadModal.vue";
export default {
  mixins: [util],
  data() {
    return {
      show: false,
      filedata: [],
      UploadRegisteredUserModalCount: 0,
    };
  },
  components: {
    moment,
    app,
    InternalRegisteredUserUploadModal,
  },
  methods: {
    ...mapActions({
      getUnpaginatedUnapprovedUsers: "appData/getUnpaginatedUnapprovedUsers",
      getExtensionApplications: "appData/getExtensionApplications",
      getJobNocApplications: "appData/getJobNocApplications",
      downloadNocToFile: "appData/downloadNocToFile",
      downloadPetitionToFile: "appData/downloadPetitionToFile",
      downloadComplaintToFile: "appData/downloadComplaintToFile",
    }),
    uploadRegisteredUsers() {
      this.UploadRegisteredUserModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("internal-registered-user-upload-modal");
      });
    },
    async onModalClosed() {
      this.$bvModal.hide("internal-registered-user-upload-modal");
      // await this.getNocsData();
    },

    async exportNocData() {
      this.show = true;
      try {
        const fileName = moment().format("YYYY-MM-DD") + "-Noc";

        const res2 = await this.downloadNocToFile();
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = "data:text/plain;base64," + res2.data.data;
        a.download = `${fileName}.war`;
        a.click();
        document.body.removeChild(a);
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    async exportPetitionsNew() {
      this.show = true;
      try {
        const fileName = moment().format("YYYY-MM-DD") + "-Petition";
        const res2 = await this.downloadPetitionToFile();
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = "data:text/plain;base64," + res2.data.data;
        a.download = `${fileName}.war`;
        a.click();
        document.body.removeChild(a);
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    async exportComplaint() {
      this.show = true;
      try {
        const fileName = moment().format("YYYY-MM-DD") + "-Complaint";

        const res = await this.downloadComplaintToFile();
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = "data:text/plain;base64," + res.data.data;
        a.download = `${fileName}.war`;
        a.click();
        document.body.removeChild(a);
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    async downloadUsersData() {
      this.show = true;

      try {
        const fileName = moment().format("YYYY-MM-DD") + "-Unapproved-Users";

        const res2 = await this.getUnpaginatedUnapprovedUsers();
        var a = document.createElement("a");

        document.body.appendChild(a);
        a.style = "display: none";
        a.href = "data:text/plain;base64," + res2.data.data;
        a.download = `${fileName}.war`;
        a.click();
        document.body.removeChild(a);
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    async downloadExtensionApplications() {
      this.show = true;

      try {
        const fileName = moment().format("YYYY-MM-DD") + "-Extension-Applications";

        const res2 = await this.getExtensionApplications();
        var a = document.createElement("a");

        document.body.appendChild(a);
        a.style = "display: none";
        a.href = "data:text/plain;base64," + res2.data.data;
        a.download = `${fileName}.war`;
        a.click();
        document.body.removeChild(a);
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    async downloadJobNocApplications() {
      this.show = true;

      try {
        const fileName = moment().format("YYYY-MM-DD") + "-Job-Noc";

        const res2 = await this.getJobNocApplications();
        var a = document.createElement("a");

        document.body.appendChild(a);
        a.style = "display: none";
        a.href = "data:text/plain;base64," + res2.data.data;
        a.download = `${fileName}.war`;
        a.click();
        document.body.removeChild(a);
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    // async downloadUsersData() {
    //   try {
    //     const res = await this.getUnpaginatedUnapprovedUsers();

    //     // this.downloadDataAsjson(res.data, "Noc-Users");

    //     (res.data);

    //     const fileName = moment().format("unapproved");
    //     var a = document.createElement("a");
    //     document.body.appendChild(a);
    //     a.style = "display: none";
    //     a.href = "data:text/plain;base64," + res.data;
    //     a.download = `${fileName}.csv`;
    //     a.click();
    //     document.body.removeChild(a);
    //   } catch (e) {
    //     this.displayError(e);
    //   }
    // },

    // downloadDataAsjson: function (arr, fileName) {
    //   const data = JSON.stringify(arr);
    //   const blob = new Blob([data], { type: "text/plain" });
    //   const e = document.createEvent("MouseEvents"),
    //     a = document.createElement("a");
    //   a.download = `${fileName}.json`;
    //   a.href = window.URL.createObjectURL(blob);
    //   a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
    //   e.initEvent(
    //     "click",
    //     true,
    //     false,
    //     window,
    //     0,
    //     0,
    //     0,
    //     0,
    //     0,
    //     false,
    //     false,
    //     false,
    //     false,
    //     0,
    //     null
    //   );
    //   a.dispatchEvent(e);
    // },

    async downloadFile(res) {
      try {
        let initialPath = process.env.VUE_APP_API.replace("/api/", "");
        // const file_path =
        //   "http://localhost:8000" + res.data.data.split("backend")[1];
        const file_path = initialPath + res.data.data.split("backend")[1];
        let temp_path_arr = file_path.split("\\");
        const file_name = temp_path_arr[temp_path_arr.length - 1];
        const res2 = await axios({
          method: "GET",
          responseType: "blob",
          url: file_path,
        });
        const file = window.URL.createObjectURL(new Blob([res2.data]));
        let link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        this.$swal({
          title: res.data.msg,
          icon: "success",
          timer: 1500,
        });
      } catch (e) {
        this.$swal({
          title: "Unable to download file",
          icon: "error",
          timer: 3000,
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      getUser: "appData/getUser",
      hasPermission: "appData/hasPermission",
    }),
  },
};
</script>

<style></style>
